import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import "./Info.css";
import {
  NFO,
  NFOLogo2x,
  Ellipse,
  QPs3_Olympiad,
  PhoneIcon,
  alert,
  x_circle,
  questionMark,
  questionMark2,
} from "../../Assets/images";

import NFOButtons, { NFOButtonTypes } from "../../Components/NFOButtons";
import PageFooter from "../../Components/PageFooter";
import NFOModals, { ModalTypes } from "../../Components/NFOModals";
import WarningModal from "./Modals/WarningModal";
import RulesModal from "./Modals/RulesModal";
import {
  fetchSamplePaperDetails,
  fetchSamplePapers,
} from "../../Utils/MockQuizAPIs/MockQuizAPIs";
import { AuthContext } from "../../App";

interface User {
  uuid: string;
  school_name: string;
  student_name: string;
  mobile_number: string;
  email: string;
  class: string;
  class_numerical: number;
}

const Info = () => {
  const navigate = useNavigate();

  const { authAws, setAuthAws } = useContext(AuthContext);

  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const handleCloseModal = (type: "rulesModal" | "warningModal") => {
    if (type == "rulesModal") {
      setIsRulesModalOpen(false);
    } else if (type == "warningModal") {
      setIsWarningModalOpen(false);
    }
  };

  const handleOpenModal = (type: "rulesModal" | "warningModal") => {
    if (type == "rulesModal") {
      setIsRulesModalOpen(true);
    } else if (type == "warningModal") {
      setIsWarningModalOpen(true);
    }
  };

  const fullScreenRef = useRef<HTMLDivElement>(null);
  const handleFullscreen = () => {
    const element = fullScreenRef.current;

    if (element && element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          console.log("Fullscreen request succeeded");
        })
        .catch((error) => {
          console.error("Fullscreen request failed:", error);
        });
    }
  };

  const handleBeginQuiz = async (): Promise<void> => {
    console.log("Handle Begin Quiz function");
    try {
      const responseSamplePaperMetadata = await fetchSamplePapers();
      console.log("Response back in Info page = ", responseSamplePaperMetadata);
      if (responseSamplePaperMetadata.data.status_code === 200) {
        let samplePaperID = 0,
          foundPaper = false;
        for (
          let i = 0;
          !foundPaper && i < responseSamplePaperMetadata.data.data.length;
          i++
        ) {
          if (responseSamplePaperMetadata.data.data[i].paid === false) {
            //Means this is a free mock paper
            samplePaperID =
              responseSamplePaperMetadata.data.data[i].samplePaperID;
            foundPaper = true;
          }
        }
        if (foundPaper) {
          localStorage.setItem("samplePaperID", samplePaperID.toString());
          console.log("Set in localStorage : samplePaperID = ", samplePaperID);
          // handleFullscreen();
          navigate("/quiz");
        } else {
          console.log("Error: No Free Sample Paper ID found");
        }
      }
    } catch (error) {
      console.log("Error in fetching Sample Papers Metadata", error);
    }
  };

  return (
    <div className="InfoContParent">
      <div ref={fullScreenRef} className="preQuizInfo">
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt=""
            src={NFO}
            style={{
              height: "100px",
              width: "auto",
              marginTop: 100,
              objectFit: "contain",
            }}
          />
        </div>

        <div
          style={{
            justifyContent: "center",
          }}
        >
          <NFOButtons
            type={NFOButtonTypes.ImageButton}
            onButtonClick={() =>
              window.open(
                "https://nfoportal.nationalfinanceolympiad.com/",
                "_self"
              )
            }
            buttonText="Hub Home"
            buttonColor="#63656715"
            textColor="#2C6AB1"
            disableElevation={true}
            buttonImageSrc={x_circle}
            imagePostion="left"
            style={{
              justifyContent: "center",
            }}
            imageStyle={{
              width: "30px",
              marginRight: "0px",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            //position: "absolute",
            //top: 0,
            flexDirection: "column",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 15,
              border: "3px solid rgba(0, 0, 0, 0.10)",
              paddingTop: 30,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 30,
              marginTop: 20,
            }}
          >
            <div>
              <img
                alt=""
                src={QPs3_Olympiad}
                style={{
                  height: "142px",
                  width: "auto",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                color: "#2C6AB1",
                textAlign: "center",
                fontFamily: "Cardo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "137.4%" /* 27.48px */,
              }}
            >
              <br /> NFO Olympiad Simulator <br />
            </div>
            <div
              style={{
                color: "#2C6AB1",
                textAlign: "center",
                fontFamily: "Cardo",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "137.4%" /* 27.48px */,
                opacity: 0.7,
              }}
            >
              The NFO Olympiad Simulator lets you test
              <br />
              your knowledge & apply your skills in an
              <br />
              exam-like environment. This series of question
              <br />
              papers helps you identify your strengths and
              <br />
              weaknesses, speeding up your preparation.
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <hr
                  style={{
                    flex: 1,
                    marginTop: "10%",
                  }}
                />

                <NFOButtons
                  onButtonClick={() => {
                    handleOpenModal("rulesModal");
                  }}
                  type={NFOButtonTypes.ImageButton}
                  buttonText="Exam Rules"
                  buttonColor="#CCE4FF"
                  textColor="rgba(44, 106, 177, 1)"
                  disableElevation={true}
                  buttonImageSrc={questionMark2}
                  imagePostion="right"
                  fontSize="12px"
                  buttonHeight="30px"
                  style={{
                    justifyContent: "center",
                    opacity: 0.9,
                  }}
                  buttonStyle={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  imageStyle={{
                    width: "30px",
                    marginRight: "0px",
                    height: "20px",
                  }}
                />

                <hr
                  style={{
                    flex: 1,
                    marginTop: "10%",
                  }}
                />
              </div>

              <NFOButtons
                type={NFOButtonTypes.GeneralButton}
                buttonText="Start First Exam"
                buttonStyle={{
                  fontSize: "13px",
                  fontWeight: "700",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                onButtonClick={() => {
                  handleOpenModal("warningModal");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <PageFooter footerText="All rights reserved | 2023" />
      <RulesModal
        isVisible={isRulesModalOpen}
        onClose={(value: boolean) => {
          handleCloseModal("rulesModal");
        }}
      />
      <WarningModal
        onPress={handleBeginQuiz}
        isVisible={isWarningModalOpen}
        onClose={(value: boolean) => {
          handleCloseModal("warningModal");
        }}
      />
    </div>
  );
};

export default Info;
