import React, { useContext, useEffect, useRef, useState } from "react";
import "./Quiz.css";
import {
  NFO,
  bgup,
  bullets,
  alert,
  whiteBullet,
  AlertIcon,
} from "../../Assets/images";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { AuthContext } from "../../App";
import { format } from "date-fns";
import questionsAndOptions from "../../Utils/Contents/QuestionPaperUtil";
import WarningModal from "./Modals/WarningModal";
import RenderMobileButtons from "../../Components/RenderMobileButtons";
import NFOButtons, { NFOButtonTypes } from "../../Components/NFOButtons";
import {
  fetchSamplePaperDetails,
  submitSamplePaperAnswers,
} from "../../Utils/MockQuizAPIs/MockQuizAPIs";
import {
  ApiResponseFetchSamplePaperDetails,
  QandOsFormat,
  QuizStateQandOs,
  SamplePaperSubmitAnswers,
} from "../../Utils/DefinitionsInterfaces";
import {
  customDecoder,
  customEncoder,
} from "../../Utils/DataEncryption/dataEncodeDecode";
import HandleUnavailableQpModal from "./Modals/HandleUnavailableQpModal";

function Quiz() {
  const {
    auth: {
      created_at,
      school_id,
      student_username,
      name,
      phone,
      email,
      grade,
      batch_id,
      invite_sent,
      report_generated,
      exam_notification_sent,
      report_id,
      paid_for_mock_olympiad,
      notify_for_new_mock_olympiads,
      school,
      examDate,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [isTabSwitchModalOpen, setIsTabSwitchModalOpen] = useState(false);
  const currDate = new Date();
  const formattedDate = format(currDate, "EEE dd MMM yyyy");
  const [currFormattedDate, setCurrFormattedDate] = useState(formattedDate);
  const navigate = useNavigate();
  const initialTime = 30 * 60 * 1000; // 30 minutes in milliseconds
  const savedTime = localStorage.getItem("quizTimer");
  const savedMilliseconds = savedTime ? parseInt(savedTime) : initialTime;
  const expiryTimestamp = new Date();
  expiryTimestamp.setMilliseconds(
    expiryTimestamp.getMilliseconds() + savedMilliseconds
  );

  /* When the openFullscreen() function is executed, open the video in fullscreen.
Note that we must include prefixes for different browsers, as they don't support the requestFullscreen method yet */

  /*
  const savedPaper = localStorage.getItem("savedQuizState");
  const originalPaper = questionsAndOptions;
  //if saved paper is retrieved, then decode it from base64
  const presentPaper = savedPaper
    ? JSON.parse(atob(savedPaper))
    : originalPaper;
*/
  const [stateQandOs, setStateQandOs] = useState<QuizStateQandOs>([]);

  const savedCurrentQuestionIndex = localStorage.getItem(
    "currentQuestionIndex"
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    savedCurrentQuestionIndex ? parseInt(savedCurrentQuestionIndex) : 0
  );

  const savedNumQuestionsAnswered = localStorage.getItem(
    "savedNumQuestionsAnswered"
  );
  const [numQuestionAnswered, setNumQuestionsAnswered] = useState(
    savedNumQuestionsAnswered ? parseInt(savedNumQuestionsAnswered) : 0
  );

  const [isQuizUnavailable, setIsQuizUnavailable] = useState(false);

  const handleTimeOver = async (): Promise<void> => {
    console.log("Time Over Now !!");
    handleFinishExam();
  };

  const { seconds, minutes, hours, isRunning, pause, resume, restart } =
    useTimer({ expiryTimestamp, autoStart: true, onExpire: handleTimeOver });

  useEffect(() => {
    if (stateQandOs.length === 0) {
      const savedEncodedStateQandOs = localStorage.getItem("savedQuizState");
      if (savedEncodedStateQandOs) {
        const decodedStateQandOs = customDecoder(savedEncodedStateQandOs);
        setStateQandOs(JSON.parse(decodedStateQandOs));
      } else {
        const samplePaperID = localStorage.getItem("samplePaperID");
        if (samplePaperID) {
          try {
            fetchQuestionPaperAndOptions(authAws, parseInt(samplePaperID));
          } catch (error) {
            console.log("Error fetching Question , Error : ", error);
            setIsQuizUnavailable(true);
          }
        } else {
          console.log("Error: samplePaperID not stored");
          setIsQuizUnavailable(true);
        }
      }
    }

    const timeLeft = `${expiryTimestamp.getTime() - new Date().getTime()}`;
    const interval = setInterval(() => {
      const saveTimeLeft = `${expiryTimestamp.getTime() - new Date().getTime()}`;
      if (parseInt(saveTimeLeft) >= 0)
        localStorage.setItem("quizTimer", saveTimeLeft);
    }, 1000);

    if (parseInt(timeLeft) / 60000 < 29) {
      setIsTabSwitchModalOpen(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    //Encoding the Question paper state using base64
    if (stateQandOs.length > 0) {
      const stringQandOs = JSON.stringify(stateQandOs);
      const encodedQandOs = customEncoder(stringQandOs);
      localStorage.setItem("savedQuizState", encodedQandOs);
    }
    localStorage.setItem(
      "savedNumQuestionsAnswered",
      numQuestionAnswered.toString()
    );
  }, [stateQandOs, numQuestionAnswered]);

  useEffect(() => {
    localStorage.setItem(
      "currentQuestionIndex",
      currentQuestionIndex.toString()
    );
  }, [currentQuestionIndex]);

  // useEffect(() => {
  //   handle.enter();
  // }, []);

  const populateQandOs = (sourceArray: ApiResponseFetchSamplePaperDetails) => {
    let arQP: QuizStateQandOs = [];
    for (let i = 0; i < sourceArray.data.length; i++) {
      let presData: QandOsFormat = {
        question: sourceArray.data[i].question,
        options: [
          sourceArray.data[i].A,
          sourceArray.data[i].B,
          sourceArray.data[i].C,
          sourceArray.data[i].D,
        ],
        isFlagged: false,
        markedOptions: [],
        questionId: sourceArray.data[i].id, //backend info, not for use in frontend
        questionNum: i + 1, //frontend info only
        topic: sourceArray.data[i].topic,
        subTopic: sourceArray.data[i].subTopic,
        question_type: sourceArray.data[i].question_type,
      };
      arQP.push(presData);
    }
    setStateQandOs(arQP);
    //if(arQP.length > 0)
    //  localStorage.setItem("savedQuizState", btoa((JSON.stringify(arQP))));
  };

  const fetchQuestionPaperAndOptions = async (
    authAws: string,
    samplePaperID: number
  ): Promise<void> => {
    try {
      console.log("authAws in Quiz page = ", authAws);
      const responseSamplePaperDetails = await fetchSamplePaperDetails(
        samplePaperID
      );
      console.log(
        "Response for Paper Details back in Quiz page = ",
        responseSamplePaperDetails
      );
      if (responseSamplePaperDetails.data.status_code === 200) {
        console.log("Successfully Received the Question Paper");
        if (responseSamplePaperDetails.data.data.length > 0) {
          populateQandOs(responseSamplePaperDetails.data);
        } else {
          console.log("Error : Empty Question Paper");
        }
      } else {
        console.log(
          "Error in Getting Question Paper with the given Sample ID =",
          samplePaperID
        );
        throw (
          "Error in Getting Question Paper with the given Sample ID = " +
          samplePaperID
        );
      }
    } catch (error) {
      console.log(
        "Error in Getting Question Paper with the given Sample ID =",
        samplePaperID
      );
      // throw("Error in Getting Question Paper with the given Sample ID = "+samplePaperID+" and error = "+error );
      setIsQuizUnavailable(true);
    }
  };

  const formatTime = (time: number): string => {
    const formattedSeconds = time < 10 ? `0${time}` : time;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    //return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleButtonClick = (buttonNumber: number) => {
    setCurrentQuestionIndex(buttonNumber - 1);
  };

  const handleOptionClick = (optionIndex: number) => {
    let newMarkedOptions = [...stateQandOs[currentQuestionIndex].markedOptions];
    const wasAnswered = newMarkedOptions.length > 0;
    const isMarked = newMarkedOptions.includes(optionIndex + 1);
    const isMCQ = currentQuestion.question_type === 'MCQ';

    if (isMCQ) {
      newMarkedOptions = isMarked ? [] : [optionIndex + 1];
    } else {
      isMarked ?
        newMarkedOptions = newMarkedOptions.filter((option) => option !== optionIndex + 1)
        : newMarkedOptions.push(optionIndex + 1);
    }

    setStateQandOs((prevState) => {
      const newState = [...prevState];
      newState[currentQuestionIndex] = {
        ...newState[currentQuestionIndex],
        markedOptions: newMarkedOptions,
      };
      return newState;
    });

    if (isMarked && newMarkedOptions.length === 0) {
      setNumQuestionsAnswered((prev) => prev - 1);
    } else if (!wasAnswered && newMarkedOptions.length > 0) {
      setNumQuestionsAnswered((prev) => prev + 1);
    }
  };

  const handleNextButtonClick = () => {
    if (currentQuestionIndex === stateQandOs.length - 1) {
      // Show modal when Finish Exam button is clicked
      setIsModalOpen(true);
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleStopExamClick = () => {
    setIsModalOpen(true);
  };

  const handleFlagQuestion = () => {
    setStateQandOs((prevState: any) => {
      const newState = [...prevState];
      console.log(
        "PrevState's flag 1= ",
        prevState[currentQuestionIndex].isFlagged
      );
      console.log(
        "NewState's flag 1= ",
        newState[currentQuestionIndex].isFlagged
      );
      newState[currentQuestionIndex] = {
        ...newState[currentQuestionIndex],
        isFlagged: !newState[currentQuestionIndex].isFlagged,
      };
      //SHOWS INCORRECT : newState[currentQuestionIndex].isFlagged = !(prevState[currentQuestionIndex].isFlagged);
      console.log(
        "PrevState's flag 2= ",
        prevState[currentQuestionIndex].isFlagged
      );
      console.log(
        "NewState's flag 2= ",
        newState[currentQuestionIndex].isFlagged
      );

      return newState;
    });
  };

  // Function to convert time string to seconds
  function timeStringToSeconds(timeString: string): number {
    const [minutes, seconds] = timeString.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  const createAnswerPaperObject = (quizState: QuizStateQandOs) => {
    let answerPaperObject: SamplePaperSubmitAnswers = {
      samplePaperID: 0,
      questions: [],
      timeTaken: "30:00",
    };
    const samplePaperID = localStorage.getItem("samplePaperID");
    const timeLeft = formatTime(seconds);
    const secondsLeft = timeStringToSeconds(timeLeft);
    const timeTakenInSeconds = 30 * 60 - secondsLeft; // total time = 30 mins
    const minsTaken = Math.floor(timeTakenInSeconds / 60);
    const secondsTaken = timeTakenInSeconds % 60;
    const timeTaken = `${minsTaken}:${secondsTaken < 10 ? "0" : ""}${secondsTaken}`;
    setQuizTimeTaken(timeTaken);

    if (samplePaperID) {
      answerPaperObject.samplePaperID = parseInt(samplePaperID);
      answerPaperObject.timeTaken = timeTaken;
      for (let i = 0; i < stateQandOs.length; i++) {
        let answerMarked: string[] = [];
        for (let j = 0; j < stateQandOs[i].markedOptions.length; j++) {
          switch (stateQandOs[i].markedOptions[j]) {
            case 1:
              answerMarked.push("A");
              break;
            case 2:
              answerMarked.push("B");
              break;
            case 3:
              answerMarked.push("C");
              break;
            case 4:
              answerMarked.push("D");
              break;
          }
        }
        const answer = {
          id: stateQandOs[i].questionId,
          answerGiven: answerMarked,
        };
        answerPaperObject.questions.push(answer);
      }
    } else {
      console.log("Error : samplePaperID not found ");
    }
    console.log("AnswerPaperObject before returning = ", answerPaperObject);
    return answerPaperObject;
  };

  const handleFinishExam = async (): Promise<void> => {
    setIsModalOpen(false);

    const answerPaperObject = createAnswerPaperObject(stateQandOs);
    try {
      const response = await submitSamplePaperAnswers(answerPaperObject);
      console.log("Response = ", response);
      if (response.status === 200) {
        if (response.data.data) {
          setStateQuizAnalysis(response.data.data);
        }
      } else {
        console.log("Error in Receiving Analysis data. Not setting it.");
      }
    } catch (error) {
      console.log("Error submitting answers = ", error);
    }
    console.log("Removed Quiz Timer from handle Finish Exam");
    localStorage.removeItem("savedQuizState");
    localStorage.removeItem("quizTimer");
    localStorage.removeItem("currentQuestionIndex");
    localStorage.removeItem("savedNumQuestionsAnswered");
    navigate("/complete", { replace: true });
  };

  const handleCloseBlankQuizPage = () => {
    setIsQuizUnavailable(false);
    navigate("/subscribedInfo", { replace: true });
  };

  const rules = [
    "Screen will go to fullscreen when exam starts",
    "Do not switch tabs during this exam, this will end the exam",
  ];
  const renderButtons = () => {
    const activeButtonStyle = {
      width: "40px",
      height: "40px",
      flexShrink: 0,
      borderRadius: "11px",
      margin: "4.5px",
      border: "2.582px solid rgba(83, 142, 195, 0.50)",
      background: "rgba(180, 219, 255, 1)",
    };

    const answeredButtonStyle = {
      width: "40px",
      height: "40px",
      flexShrink: 0,
      borderRadius: "11px",
      margin: "4.5px",
      border: "2.582px solid rgba(83, 142, 195, 0.50)",
      background: "rgba(139, 224, 230, 1)",
    };

    const inactiveButtonStyle = {
      width: "40px",
      height: "40px",
      flexShrink: 0,
      borderRadius: "11px",
      margin: "4.5px",
      border: "2.582px solid rgba(228, 231, 234, 1)",
      background: "rgba(237,241,244,1)",
    };

    const flaggedButtonStyle = {
      width: "40px",
      height: "40px",
      flexShrink: 0,
      borderRadius: "11px",
      margin: "4.5px",
      //border: "2.582px solid rgba(230, 57, 70, 0.3)",
      //background: "rgba(230, 57, 70, 0.6)",
      border: "2.58px solid rgba(146, 155, 47, 0.5)", //yellowish border
      background: "rgba(205, 218, 60, 0.2)", //yellow background
    };

    const buttons = [];
    for (let i = 1; i <= stateQandOs.length; i++) {
      const buttonStyle =
        i === currentQuestionIndex + 1
          ? activeButtonStyle
          : stateQandOs[i - 1].isFlagged
            ? flaggedButtonStyle
            : stateQandOs[i - 1].markedOptions.length === 0
              ? inactiveButtonStyle
              : answeredButtonStyle;

      buttons.push(
        <button
          key={i}
          style={buttonStyle}
          onClick={() => handleButtonClick(i)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const currentQuestion = stateQandOs[currentQuestionIndex];

  const fullscreenRef = useRef<HTMLDivElement>(null);

  const isMounted = useRef(true);

  useEffect(() => {
    const handleRefresh = () => {
      if (isMounted.current && performance.navigation.type === 1) {
        console.log("Page was refreshed");
      }
    };

    window.addEventListener("beforeunload", handleRefresh);
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const element = fullscreenRef.current;
    const handleFullscreenChange = () => {
      if (
        document.fullscreenElement === element &&
        document.visibilityState === "visible"
      ) {
        console.log("Entered fullscreen mode");
      } else if (
        document.fullscreenElement !== element ||
        document.visibilityState === "hidden"
      ) {
        setIsTabSwitchModalOpen(true);
        console.log("Exited fullscreen mode");
      }
    };

    document.addEventListener("visibilitychange", handleFullscreenChange);
    // document.addEventListener("fullscreenchange", handleFullscreenChange);

    // if (
    //   element &&
    //   element.requestFullscreen &&
    //   isTabSwitchModalOpen === false
    // ) {
    //   element
    //     .requestFullscreen()
    //     .then(() => {
    //       console.log("Fullscreen request succeeded");
    //     })
    //     .catch((error) => {
    //       setIsTabSwitchModalOpen(true);
    //       console.error("Fullscreen request failed:", error);
    //     });
    // }

    return () => {
      document.removeEventListener("visibilitychange", handleFullscreenChange);
      // document.removeEventListener("fullscreenchange", handleFullscreenChange);

      if (
        document.fullscreenElement === element &&
        document.exitFullscreen &&
        document.visibilityState === "hidden"
      ) {
        document
          .exitFullscreen()
          .then(() => {
            setIsTabSwitchModalOpen(true);
            console.log("Exited fullscreen mode return");
          })
          .catch((error) => {
            console.error("Exit fullscreen failed:", error);
          });
      }
    };
  }, [fullscreenRef, isTabSwitchModalOpen, isModalOpen]); // Include fullscreenRef in the dependency array

  return (
    <div style={{}}>
      {isQuizUnavailable && (
        <HandleUnavailableQpModal
          cbCloseBlankQuizPage={handleCloseBlankQuizPage}
        />
      )}
      {stateQandOs.length > 0 && (
        <div
          ref={fullscreenRef}
          id="quiz"
          className="portal"
          style={{ backgroundColor: "#f3f3f3", height: "100vh" }}
        >
          <div className="qcontentwrap">
            <div className="header">
              <div className="date">
                NFO Mockquiz
                <br />
                <div style={{ color: "rgba(44, 106, 177, 0.5)" }}>
                  {currFormattedDate}
                </div>
              </div>
              <img src={bgup} alt="" className="bgup" />
              <img className="logo-icon" alt="" src={NFO} />
            </div>

            <div className="mobile-remaining-time">
              <h1
                style={{
                  color: "rgba(17, 67, 197, 1)",
                  fontSize: "25px",
                  height: "100px",
                  marginTop: "0px",
                }}
              >
                <p style={{ marginTop: 0, opacity: 0.4 }}>Time remaining</p>
                <p style={{ marginBottom: 0 }}>{formatTime(seconds)}</p>
              </h1>
            </div>

            <div className="contentwrap1">
              <div className="row">
                <div className="studentid-wrap">
                  <div className="bruh">
                    <div className="remaining-time">
                      <p style={{ marginTop: 10 }}>Time remaining</p>
                      <h1>{formatTime(seconds)}</h1>
                    </div>
                  </div>
                  <div className="buttons-matrix">{renderButtons()}</div>
                  <RenderMobileButtons
                    classname="mobile-buttons-matrix"
                    stateCurrentQuestionIndex={currentQuestionIndex}
                    stateQandOs={stateQandOs}
                    cbHandleButtonClick={handleButtonClick}
                  />
                  <button
                    className="stop"
                    onClick={() => {
                      handleStopExamClick();
                      console.log("hello");
                    }}
                  >
                    Stop Exam
                  </button>
                </div>
              </div>
              <div className="qguidecard">
                <div className="questionWithSerialNumber">
                  <div className="qSerialNum">Q{currentQuestionIndex + 1}.</div>
                  <div className="question">{currentQuestion.question}</div>
                </div>
                {
                  currentQuestion.question_type == 'MSQ' ?
                    <div className="question-msq">you can select 1 or more options</div> : <div></div>
                }
                {
                  currentQuestion.options.map(
                    (option, index) =>
                      option && (
                        <div
                          className="options"
                          key={index}
                          style={{
                            background: stateQandOs[currentQuestionIndex].markedOptions.includes(index + 1) ? "rgba(180, 219, 255, 0.50)" : "",
                            display: "flex",
                            gap: '10px',
                          }}
                          onClick={() => handleOptionClick(index)}
                        >
                          <input
                            type="checkbox"
                            className="option-checkbox"
                            checked={stateQandOs[currentQuestionIndex].markedOptions.includes(index + 1)}
                          />
                          <div style={{ width: '95%' }}>{option}</div>

                        </div>
                      )
                  )
                }
                <div className="flag">
                  {stateQandOs[currentQuestionIndex].isFlagged && (
                    <img
                      src={bullets}
                      alt=""
                      style={{
                        top: "-20px",
                        cursor: "pointer",
                      }}
                      onClick={handleFlagQuestion}
                    />
                  )}
                  {!stateQandOs[currentQuestionIndex].isFlagged && (
                    <img
                      src={whiteBullet}
                      alt=""
                      style={{
                        top: "-20px",
                        cursor: "pointer",
                      }}
                      onClick={handleFlagQuestion}
                    />
                  )}
                  <a
                    style={{
                      position: "relative",
                      bottom: "-2px",
                      cursor: "pointer",
                    }}
                    onClick={handleFlagQuestion}
                  >
                    &nbsp;Mark question for later
                  </a>
                </div>
                <div className="mobile-flag">
                  {stateQandOs[currentQuestionIndex].isFlagged && (
                    <img
                      src={bullets}
                      alt=""
                      width={15}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleFlagQuestion}
                    />
                  )}
                  {!stateQandOs[currentQuestionIndex].isFlagged && (
                    <img
                      src={whiteBullet}
                      alt=""
                      width={15}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleFlagQuestion}
                    />
                  )}
                  <a
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={handleFlagQuestion}
                  >
                    &nbsp;Mark question for later
                  </a>
                </div>
                <div className="next">
                  <div className="qCompleted">
                    Completed <br />
                    {`questions : ${numQuestionAnswered}/${stateQandOs.length}`}
                  </div>
                  <button className="next-btn" onClick={handleNextButtonClick}>
                    {currentQuestionIndex === stateQandOs.length - 1
                      ? "Submit and finish Exam"
                      : "Proceed to next question"}
                  </button>
                  <button
                    className="mobile-next-btn"
                    onClick={handleNextButtonClick}
                  >
                    {currentQuestionIndex === stateQandOs.length - 1
                      ? "Finish Exam"
                      : "Next Question"}
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <button
                className="mobile-stop"
                onClick={() => {
                  handleStopExamClick();
                }}
              >
                Stop Exam
              </button>
            </div>
          </div>
          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                backgroundColor: "rgba(0,0,0,0.9)",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: "flex",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 15,
                  padding: "10px",
                  border: "none",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                  maxWidth: "320px",
                  maxHeight: "300px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    color: "rgba(44, 106, 177, 1)",
                    marginBottom: "10px",
                    fontSize: "20px",
                  }}
                >
                  Attention
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={AlertIcon}
                    style={{
                      height: "65px",
                      width: "65px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      alignSelf: "center",
                    }}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        color: "rgba(44, 106, 177, 1)",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        fontSize: "16px",
                        textAlign: "left",
                      }}
                    >
                      {`Please confirm to submit your exam attempt`}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <NFOButtons
                    onButtonClick={() => {
                      setIsModalOpen(false);
                    }}
                    type={NFOButtonTypes.GeneralButton}
                    buttonText="Go Back"
                    disableElevation={true}
                    style={{ marginLeft: "5px" }}
                    fontSize="13px"
                    buttonStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
                  />
                  <NFOButtons
                    onButtonClick={() => {
                      handleFinishExam();
                    }}
                    type={NFOButtonTypes.GeneralButton}
                    style={{ marginLeft: "15px" }}
                    buttonColor="#C53111"
                    buttonText="Submit Exam"
                    fontSize="13px"
                    disableElevation={true}
                  />

                  {/* <button className="cont">Purchase</button> */}
                </div>
              </div>
            </div>
          )}
          <b className="footer">All rights reserved | 2023 </b>
          <b className="mobile-footer">All rights reserved | 2023 </b>

          <WarningModal
            headingText="Screen Switching Detected"
            primaryButtonText="Continue olympiad"
            warningText="Please do not switch screens during the exam. Multiple attempts will lead to disqualification."
            isVisible={isTabSwitchModalOpen}
            onClickPrimaryButton={() => {
              // handle.enter();

              setIsTabSwitchModalOpen(false);
            }}
            onClickSecondaryButton={() => {
              handleFinishExam();
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Quiz;
