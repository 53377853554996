import React, { useContext } from "react";
import "./MobileSolutionAndReasoningCards.css";
import { AuthContext } from "../../../App";
import {
  bullets,
  greenBullet,
  lightGreenBullet,
  orangeBullet,
  whiteBullet,
} from "../../../Assets/images";

interface propsSolutionCardsInterface {
  currentSolutionIndex: number;
}

const MobileSolutionAndReasoningCards = (
  props: propsSolutionCardsInterface
) => {
  const {
    auth: {
      school_name,
      student_name,
      email,
      class_numerical,
      mobile_number,
      uuid,
    },
    authAws,
    stateQuizAnalysis,
    setStateQuizAnalysis,
    quizTimeTaken,
    setQuizTimeTaken,
  } = useContext(AuthContext);

  const currentQuestionDetails =
    stateQuizAnalysis.questions[props.currentSolutionIndex];

  return (
    <div className="mobile-solCard-SolutionAndReasoningWrapper">
      <div className="mobile-solCard-qguidecard">
        <div className="mobile-solCard-questionWithSerialNumber">
          <div className="mobile-solCard-qSerialNum">
            Q{props.currentSolutionIndex + 1}.
          </div>
          <div className="mobile-solCard-question-during-analysis">
            {currentQuestionDetails.question}
          </div>
        </div>
        <div
          className="mobile-sol-options"
          key={1}
          style={{
            background:
              currentQuestionDetails.answerSubmitted.includes("A") &&
              currentQuestionDetails.correctAnswer.includes("A")
                ? "rgba(201, 242, 155, 0.50)"
                : currentQuestionDetails.answerSubmitted.includes("A")
                ? "rgba(255, 193, 180, 0.5)"
                : currentQuestionDetails.correctAnswer.includes("A")
                ? "rgba(115, 250, 10, 0.2)"
                : "",
            maxWidth: 700,
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {!currentQuestionDetails.answerSubmitted.includes("A") &&
            !currentQuestionDetails.correctAnswer.includes("A") && (
              <img src={whiteBullet} alt="" />
            )}
          {currentQuestionDetails.answerSubmitted.includes("A") &&
            !currentQuestionDetails.correctAnswer.includes("A") && (
              <img src={orangeBullet} alt="" />
            )}
          {currentQuestionDetails.correctAnswer.includes("A") && (
            <img src={greenBullet} alt="" />
          )}
          &nbsp;&nbsp;
          <div>{currentQuestionDetails.A}</div>
        </div>
        <div
          className="mobile-sol-options"
          key={2}
          style={{
            background:
              currentQuestionDetails.answerSubmitted.includes("B") &&
              currentQuestionDetails.correctAnswer.includes("B")
                ? "rgba(201, 242, 155, 0.50)"
                : currentQuestionDetails.answerSubmitted.includes("B")
                ? "rgba(255, 193, 180, 0.5)"
                : currentQuestionDetails.correctAnswer.includes("B")
                ? "rgba(115, 250, 10, 0.2)"
                : "",
            maxWidth: 700,
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {!currentQuestionDetails.answerSubmitted.includes("B") &&
            !currentQuestionDetails.correctAnswer.includes("B") && (
              <img src={whiteBullet} alt="" />
            )}
          {currentQuestionDetails.answerSubmitted.includes("B") &&
            !currentQuestionDetails.correctAnswer.includes("B") && (
              <img src={orangeBullet} alt="" />
            )}
          {currentQuestionDetails.correctAnswer.includes("B") && (
            <img src={greenBullet} alt="" />
          )}
          &nbsp;&nbsp;
          <div>{currentQuestionDetails.B}</div>
        </div>
        <div
          className="mobile-sol-options"
          key={3}
          style={{
            background:
              currentQuestionDetails.answerSubmitted.includes("C") &&
              currentQuestionDetails.correctAnswer.includes("C")
                ? "rgba(201, 242, 155, 0.50)"
                : currentQuestionDetails.answerSubmitted.includes("C")
                ? "rgba(255, 193, 180, 0.5)"
                : currentQuestionDetails.correctAnswer.includes("C")
                ? "rgba(115, 250, 10, 0.2)"
                : "",
            maxWidth: 700,
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {!currentQuestionDetails.answerSubmitted.includes("C") &&
            !currentQuestionDetails.correctAnswer.includes("C") && (
              <img src={whiteBullet} alt="" />
            )}
          {currentQuestionDetails.answerSubmitted.includes("C") &&
            !currentQuestionDetails.correctAnswer.includes("C") && (
              <img src={orangeBullet} alt="" />
            )}
          {currentQuestionDetails.correctAnswer.includes("C") && (
            <img src={greenBullet} alt="" />
          )}
          &nbsp;&nbsp;
          <div>{currentQuestionDetails.C}</div>
        </div>
        {currentQuestionDetails.D !== "" && (
          <div
            className="mobile-sol-options"
            key={4}
            style={{
              background:
                currentQuestionDetails.answerSubmitted.includes("D") &&
                currentQuestionDetails.correctAnswer.includes("D")
                  ? "rgba(201, 242, 155, 0.50)"
                  : currentQuestionDetails.answerSubmitted.includes("D")
                  ? "rgba(255, 193, 180, 0.5)"
                  : currentQuestionDetails.correctAnswer.includes("D")
                  ? "rgba(115, 250, 10, 0.2)"
                  : "",
              maxWidth: 700,
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {!currentQuestionDetails.answerSubmitted.includes("D") &&
              !currentQuestionDetails.correctAnswer.includes("D") && (
                <img src={whiteBullet} alt="" />
              )}
            {currentQuestionDetails.answerSubmitted.includes("D") &&
              !currentQuestionDetails.correctAnswer.includes("D") && (
                <img src={orangeBullet} alt="" />
              )}
            {currentQuestionDetails.correctAnswer.includes("D") && (
              <img src={greenBullet} alt="" />
            )}
            &nbsp;&nbsp;
            <div>{currentQuestionDetails.D}</div>
          </div>
        )}
      </div>
      <div className="mobile-solCard-qguidecard">
        <div
          className="mobile-solCard-questionWithSerialNumber"
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            className="mobile-solCard-question-during-analysis"
            style={{
              fontStyle: "italic",
              marginBottom: 10,
              fontWeight: "lighter",
              opacity: "0.5",
            }}
          >
            Reasoning
          </div>
          <div
            className="mobile-solCard-question-during-analysis"
            style={{
              marginBottom: 10,
              fontSize: 16,
              fontWeight: "bolder",
            }}
          >
            {currentQuestionDetails.reasoning}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSolutionAndReasoningCards;
